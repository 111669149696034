import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  formGroup: FormGroup
  constructor(private as: AuthService, private fb: FormBuilder) {
    this.formGroup = this.fb.group({
      email: ["", Validators.required],
      password: ["", Validators.required]
    })
  }
  userLogin() {
    if (this.formGroup.valid) {
      this.as.login(this.formGroup.value).subscribe((res) => {
        console.log(res)
        Swal.fire(res.message)
        if (res.status == true) {
          localStorage.setItem("sweit_user", JSON.stringify(res.data));
          window.location.assign('/dashboard');
        }
      })
    } else {
      Swal.fire("Please enter details")
    }
  }
  ngOnInit() { }

}
