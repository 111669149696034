import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
const routes = {
  operators: () => environment.webUrl + environment.apiUrl + `operators`,
};
const headers: HttpHeaders = new HttpHeaders(
  {
  'Accept':'application/json',
  'appType': 'abdc12d02622aa11'
  });
@Injectable()
export class OperatorsService {
  constructor(private http: HttpClient) { }
  getOperators(page,text) {
    return this.http.get(routes.operators()+"?page="+page+"&search="+text, {headers: headers}).pipe(
      map((body: any) => body),
      catchError((error: any) => error)
    );
  }

  putOperators(data, id) {
    return this.http.put(routes.operators() + '/' + id, data, {headers: headers}).pipe(
      map((body: any) => body),
      catchError((error: any) => error)
    );
  }
  adOperators(data, id) {
    return this.http.post(routes.operators(), data, {headers: headers}).pipe(
      map((body: any) => body),
      catchError((error: any) => error)
    );
  }
}
