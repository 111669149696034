import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Cashvisory Admin';
  currentUrl = false;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
    ){
      let url =window.location.href.split("/")[3];
      if(url=="login" || url==""){
        this.currentUrl=true;
      }
    }
}
