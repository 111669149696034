import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
const routes = {
  coupons: () => environment.webUrl + environment.apiUrl + `coupons`,
  couponLists: () => environment.webUrl + environment.apiUrl + `coupon-lists`,
};
const headers: HttpHeaders = new HttpHeaders(
  {
  'Accept':'application/json',
  'appType': 'abdc12d02622aa11'
  });
@Injectable()
export class CouponsService {
  constructor(private http: HttpClient) { }
  getCoupons(page) {
    return this.http.get(routes.coupons()+"?page="+page, {headers: headers}).pipe(
      map((body: any) => body),
      catchError((error: any) => error)
    );
  }
  addCoupons(data) {
    return this.http.post(routes.coupons(), data, {headers: headers}).pipe(
      map((body: any) => body),
      catchError((error: any) => error)
    );
  }
  putCoupons(data, id) {
    return this.http.put(routes.coupons() + '/' + id, data, {headers: headers}).pipe(
      map((body: any) => body),
      catchError((error: any) => error)
    );
  }
}
