import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
const routes = {
  transactions: () => environment.webUrl + environment.apiUrl + `transactions`,
};
const headers: HttpHeaders = new HttpHeaders(
  {
  'Accept':'application/json',
  'appType': 'abdc12d02622aa11'
  });
@Injectable()
export class TransactionsService {
  constructor(private http: HttpClient) { }
  getTransactions(page) {
    return this.http.get(routes.transactions()+"?page="+page, {headers: headers}).pipe(
      map((body: any) => body),
      catchError((error: any) => error)
    );
  }
  addTransactions(data) {
    return this.http.post(routes.transactions(), data, {headers: headers}).pipe(
      map((body: any) => body),
      catchError((error: any) => error)
    );
  }
  putTransactions(data, id) {
    return this.http.put(routes.transactions() + '/' + id, data, {headers: headers}).pipe(
      map((body: any) => body),
      catchError((error: any) => error)
    );
  }
}
