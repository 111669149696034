import { Component, OnInit } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { SettingsService } from '../../services/settings/settings.service';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SettingsComponent implements OnInit {
  settings: any = [];
  keys: any = [];
  formGroup:FormGroup;
  itemName: any = [];
  itemDetails: any = [];
  constructor(private as: SettingsService,private modalService: NgbModal,private fb:FormBuilder) {
    this.as.getSettings().subscribe((res) => {
      if (res.status == true) {
        this.settings = res.data;
      }
    })
  }
  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
  }
  ngOnInit() {}
  settingEdit(item,content){
    this.formGroup=this.fb.group(item)
  
    this. open(content);
    this.itemDetails=item
  }
  onUpdate(content){
    this.settings[this.itemDetails[0]]=Number(this.formGroup.value.data); 
    this.as.editSettings(this.formGroup.value,this.itemDetails._id).subscribe((res) => {
      if (res.status == true) {
        Swal.fire("Settings Update successfull")
        this.as.getSettings().subscribe((res) => {
          if (res.status == true) {
            this.settings = res.data;
          }
        })
      }
    });
  }
}
