import { Component, OnInit } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { SettingsService } from '../../services/settings/settings.service';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PagesComponent implements OnInit {
  pages: any = [];
  formGroup:FormGroup;
  itemName: any = [];
  itemDetails: any = [];
  editorConfig: AngularEditorConfig = {
    editable: true,
     
};
  constructor(private as: SettingsService,private modalService: NgbModal,private fb:FormBuilder) {
    this.as.getPages().subscribe((res) => {
      if (res.status == true) {
        this.pages = res.data;
      }
    })
  }
  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title',size: 'xl',});
  }
  ngOnInit() {}
  settingEdit(item,content){
    this.formGroup=this.fb.group(item)
  
    this. open(content);
    this.itemDetails=item
  }
  onUpdate(content){
    this.as.editPages(this.formGroup.value,this.itemDetails._id).subscribe((res) => {
      if (res.status == true) {
        Swal.fire("CMS Update successfull")
        this.as.getPages().subscribe((res) => {
          if (res.status == true) {
            this.pages = res.data;
          }
        })
      }
    });
  }
}
