import { Component, OnInit } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { TransactionsService } from '../../services/transactions/transactions.service';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TransactionsComponent implements OnInit {
  transactions: any = [];
  formGroup:FormGroup;
  itemName: any = [];
  itemDetails: any = [];
  constructor(private as: TransactionsService,private modalService: NgbModal,private fb:FormBuilder) {
    this.as.getTransactions(1).subscribe((res) => {
      if (res.status == true) {
        this.transactions = res;
      }
    })
  }
  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
  }
  ngOnInit() {}
  assumptionsEdit(item,content){
    this.formGroup=this.fb.group({data:item[1]})
  
    this. open(content);
    this.itemDetails=item
  }
  onPageChange(page){
    this.as.getTransactions(page).subscribe((res) => {
      if (res.status == true) {
        this.transactions = res;
      }
    })
  }
  onUpdate(content){
    // this.users[this.itemDetails[0]]=Number(this.formGroup.value.data); 
    // this.as.putAssumption(this.assumptions,this.assumptions._id).subscribe((res) => {
    //   if (res.success == true) {
    //     this.keys=Object.keys(res.data);
    //     this.keys=this.keys.filter((item)=>{
    //         return item!="__v" && item!="_id";
    //     })
    //     this.keys.forEach(item => {
    //       this.itemName.push(item.replace(/_/g," "))
    //     });
    //     this.assumptions = res.data;
    //   }
    // });
  }
}
