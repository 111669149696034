import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
const routes = {
  users: () => environment.webUrl + environment.apiUrl + `users`,
  transactions: () => environment.webUrl + environment.apiUrl + `transactions/user/`,
  wallethistories: () => environment.webUrl + environment.apiUrl + `wallet-histories/user/`,
};
const headers: HttpHeaders = new HttpHeaders(
  {
  'Accept':'application/json',
  'appType': 'abdc12d02622aa11'
  });
@Injectable()
export class UsersService {
  constructor(private http: HttpClient) { }
  getUsers(page,text) {
    return this.http.get(routes.users()+"?page="+page+"&search="+text, {headers: headers}).pipe(
      map((body: any) => body),
      catchError((error: any) => error)
    );
  }
  getTransactions(id,page) {
    return this.http.get(routes.transactions()+id+"?page="+page, {headers: headers}).pipe(
      map((body: any) => body),
      catchError((error: any) => error)
    );
  }
  getWalletHistories(id,type,page) {
    return this.http.get(routes.wallethistories()+id+"?page="+page+"&type="+type, {headers: headers}).pipe(
      map((body: any) => body),
      catchError((error: any) => error)
    );
  }
  putUsers(data, id) {
    return this.http.put(routes.users() + '/' + id, data, {headers: headers}).pipe(
      map((body: any) => body),
      catchError((error: any) => error)
    );
  }
}
