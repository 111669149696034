import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
const routes = {
  login: () => environment.webUrl + environment.apiUrl + `admin-login`,
  loanRegister: () => environment.webUrl + environment.apiUrl + `loan-register`,
};
const headers: HttpHeaders = new HttpHeaders(
  {
  'Accept':'application/json',
  'appType': 'abdc12d02622aa11'
  });
@Injectable()
export class AuthService {
 
  constructor(private http: HttpClient) { }
  login(data) {
    return this.http.post(routes.login(),data, {headers: headers}).pipe(
      map((body: any) => body),
      catchError((error: any) => error)
    );
  }
}
