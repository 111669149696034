import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UsersService } from '../../../services/users/users.service';
@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class UserTransactionsComponent implements OnInit {

  transactions: any = [];
  wallet: any = [];
  commision: any = [];
  recharge: any = [];
  constructor(private as: UsersService,private route:ActivatedRoute) {
    this.as.getTransactions(route.snapshot.params.id,1).subscribe((res) => {
      if (res.status == true) {
        this.transactions = res.data;
      }
    })
    this.as.getWalletHistories(route.snapshot.params.id,"Commission",1).subscribe((res) => {
      if (res.status == true) {
        this.commision = res.data;
      }
    })
    this.as.getWalletHistories(route.snapshot.params.id,"Recharge",1).subscribe((res) => {
      if (res.status == true) {
        this.recharge = res.data;
      }
    })
    this.as.getWalletHistories(route.snapshot.params.id,"Wallet",1).subscribe((res) => {
      if (res.status == true) {
        this.wallet = res.data;
      }
    })
  }
  onPageChangeWallet(page){
    this.as.getWalletHistories(this.route.snapshot.params.id,"Wallet",page).subscribe((res) => {
      if (res.status == true) {
        this.wallet = res.data;
      }
    })
  }
  onPageChangeRecharge(page){
    this.as.getWalletHistories(this.route.snapshot.params.id,"Recharge",page).subscribe((res) => {
      if (res.status == true) {
        this.recharge = res.data;
      }
    })
  }
  onPageChangeCommission(page){
    this.as.getWalletHistories(this.route.snapshot.params.id,"Commission",page).subscribe((res) => {
      if (res.status == true) {
        this.transactions = res.data;
      }
    })
  }
  onPageChange(page){
    this.as.getTransactions(this.route.snapshot.params.id,page).subscribe((res) => {
      if (res.status == true) {
        this.transactions = res.data;
      }
    })
  }
  ngOnInit() {
  }

}
